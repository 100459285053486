<template>
  <div class="file-uploader">
    <b-overlay
      :show="isLoading"
      variant="white"
      rounded="sm"
      spinner-variant="warning"
    >
      <h4>Файли</h4>
      <p>Підтримувані формати: {{ allowedTypesString }} <br /></p>
      <div
        class="file-item d-flex align-items-center justify-comtent-between"
        v-for="(n, i) in this.files_count"
        :key="i"
      >
        <svg
          v-if="file_inputs[i]"
          width="22"
          height="22"
          class="file-icon"
          viewBox="0 0 32 32"
          fill="#023945"
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 3"
        >
          <g>
            <path
              d="m20 9h4.51715a.2.2 0 0 0 .14142-.34143l-5.31714-5.3172a.2.2 0 0 0 -.34143.14142v4.51721a1 1 0 0 0 1 1z"
            />
            <path
              d="m25 11h-5a3 3 0 0 1 -3-3v-4.99744a.997.997 0 0 0 -.99609-1c-2.34235-.00122-8.00391-.00256-8.00391-.00256a2 2 0 0 0 -2 2v24a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-16a1 1 0 0 0 -1-1z"
            />
          </g>
        </svg>
        <label :for="'file-' + i" class="d-block w-100">
          <input
            :id="'file-' + i"
            :name="'file-' + i"
            type="file"
            v-show="!file_inputs[i]"
            :ref="'file-' + i"
            @change="uploadFile($event, i)"
          />

          <span class="file-name" v-if="file_results[i]">
            {{ file_results[i].orig_path }}
          </span>
        </label>
        <a @click.prevent="removeFile(i)" href="#" v-if="file_inputs[i]">
          <svg
            fill="#f00"
            width="22"
            height="22"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="m255.575 476.292c-28.978.054-57.68-5.62-84.458-16.694s-51.103-27.331-71.5785-47.836c-86.0513-86.051-86.0513-226.057 0-312.108 20.4445-20.5595 44.7645-36.8599 71.5515-47.9576 26.786-11.0978 55.508-16.7725 84.503-16.6956 58.95 0 114.37 22.9517 156.036 64.6532 41.684 41.684 64.653 97.103 64.653 156.054s-22.952 114.37-64.653 156.054c-20.479 20.505-44.808 36.762-71.588 47.836-26.781 11.074-55.486 16.747-84.466 16.694zm.018-405.9809c-24.357-.0691-48.485 4.6953-70.987 14.0174s-42.931 23.0165-60.103 40.2895c-35.0103 35.011-54.2898 81.567-54.2898 131.09s19.2795 96.062 54.2898 131.09c72.28 72.28 189.899 72.298 262.162 0 35.01-35.01 54.307-81.567 54.307-131.09s-19.28-96.062-54.307-131.09c-17.173-17.268-37.599-30.9588-60.097-40.2806-22.499-9.3218-46.622-14.0892-70.975-14.0263z"
              />
              <path
                d="m180.677 348.25c-3.495.008-6.914-1.023-9.822-2.961-2.908-1.939-5.175-4.698-6.512-7.927-1.338-3.229-1.685-6.783-1-10.21.686-3.427 2.375-6.573 4.852-9.039l149.804-149.804c1.639-1.639 3.585-2.939 5.727-3.827 2.141-.887 4.437-1.343 6.755-1.343s4.614.456 6.755 1.343c2.142.888 4.088 2.188 5.727 3.827s2.94 3.585 3.827 5.727 1.344 4.437 1.344 6.755-.457 4.614-1.344 6.756c-.887 2.141-2.188 4.087-3.827 5.726l-149.804 149.805c-1.635 1.645-3.58 2.949-5.723 3.837-2.142.888-4.44 1.342-6.759 1.335z"
              />
              <path
                d="m330.491 348.25c-2.319.003-4.615-.453-6.757-1.341-2.143-.887-4.088-2.19-5.725-3.831l-149.805-149.805c-1.639-1.639-2.939-3.585-3.826-5.726-.887-2.142-1.344-4.438-1.344-6.756s.457-4.613 1.344-6.755 2.187-4.088 3.826-5.727c1.64-1.639 3.586-2.939 5.727-3.827 2.142-.887 4.438-1.343 6.756-1.343s4.613.456 6.755 1.343c2.142.888 4.088 2.188 5.727 3.827l149.804 149.804c2.477 2.466 4.166 5.612 4.851 9.039.686 3.427.338 6.981-.999 10.21-1.338 3.229-3.604 5.988-6.512 7.927-2.909 1.938-6.327 2.969-9.822 2.961z"
              />
            </g>
          </svg>
        </a>
      </div>
      <div
        class="d-flex justify-content-end"
        v-if="files_count < maxFilesCount"
      >
        <b-button class="add-file" @click="addFile" variant="outline-primary">
          Додати файл
        </b-button>
      </div>
    </b-overlay>
    <b-modal v-model="showErrorModal" hide-footer hide-header centered>
      <h3>Помилка</h3>
      <b-alert show variant="danger">{{ error_msg.type }}</b-alert>

      <div class="d-flex w-100">
        <b-button @click="closeModal" variant="outline-danger w-100" block>
          Закрити
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    maxFilesCount: { default: 3, type: Number },
  },
  data() {
    return {
      isLoading: false,
      file_inputs: [],
      file_results: [],
      allowed_types: [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "image/jpg",
        "docx",
        "doc",
      ],
      max_size: 15,
      files_count: 1,
      showErrorModal: false,
      error_msg: {
        type: "Тип файлу не підтримується",
      },
    };
  },
  computed: {
    allowedTypesString() {
      let types = [];
      this.allowed_types.forEach((t) => {
        if (t.includes("/")) {
          types.push(t.split("/")[1]);
        } else {
          types.push(t);
        }
      });
      return types.join(", ");
    },
  },
  methods: {
    closeModal() {
      this.showErrorModal = false;
    },
    removeFile(i) {
      this.file_results[i] = null;
      this.file_inputs[i] = null;
      var ref = "file-" + i;
      this.$refs[ref].value = null;
      this.file_inputs[i] = null;
      this.$emit("update", this.file_results);
    },
    addFile() {
      if (this.maxFilesCount && this.files_count < Number(this.maxFilesCount)) {
        this.files_count++;
      }
    },
    clearFileInput(i) {
      var ref = "file-" + i;
      this.$refs[ref].value = null;
      this.file_inputs[i] = null;
    },
    uploadFile(e, n) {
      console.log(e.target, n);
      var file = e.target.files[0];
      //check file type
      if (!this.allowed_types.includes(file.type)) {
        this.showErrorModal = true;
        this.error_msg.type = "Тип файлу не підтримується";
        this.clearFileInput(n);
        return;
      }
      //check file size
      if (file.size > this.max_size * 1024 * 1024) {
        this.showErrorModal = true;
        this.error_msg.type = `Розмір файлу не повинен перевищувати ${this.max_size} МБ`;
        this.clearFileInput(n);
        return;
      }
      this.file_inputs.push(file);
      this.sendFile(e, n);
    },
    sendFile(e, i) {
      this.isLoading = true;
      var formData = new FormData();
      formData.append("uid", this.$store.state.user.uid);
      var file = this.file_inputs[i];
      formData.append("file", file);
      this.$fileApi
        .post(
          "https://stryi-rada.gov.ua/wp-json/myplugin/v1/upload-file",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((response) => {
          if (response.data.status == "ok") {
            this.file_results.push(response.data);
            this.$emit("update", this.file_results);
          } else {
            this.clearFileInput(i);
          }
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
          this.clearFileInput(i);
          this.$toast.error("Помилка завантаження файлу");
        });
    },
  },
};
</script>

<style lang="scss">
.add-file {
  height: 32px !important;
  font-size: 14px !important;
}
.file-uploader {
  margin-top: 30px;
  margin-bottom: 30px;
  background: lighten(#e7e7e7, 5%);
  padding: 16px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.file-item {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 6px;
  line-height: 1;
  label {
    margin: 0px !important;
  }
  input {
    margin: 5px;
    font-size: 13px;
  }
}
</style>
