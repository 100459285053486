<template>
  <div class="item pl-2 mb-3">
    <div class="d-flex mb-1">
      <div class="d-flex align-items-center">
        <status-badge :status="event.prev_status" />
        <span class="mx-1"> → </span>
        <status-badge :status="event.next_status" />
        <strong class="moderation-badge ml-2" v-if="!event.approved"
          >Чекає модерації</strong
        >
      </div>
      <div class="date text-muted ml-auto" v-if="event.created_at">
        {{ event.created_at.seconds | moment("calendar") }}
      </div>
    </div>
    <p class="mb-1 mt-1">
      <em>{{ event.response_text }}</em>
    </p>
    <div class="meta pt-1 d-flex">
      <div class="meta__item mr-2">
        Публічна відповідь:
        <strong>{{ event.make_public ? "Так" : "Ні" }}</strong>
      </div>
      <div class="meta__item mr-2">
        Показати автора:
        <strong>{{ event.make_author_public ? "Так" : "Ні" }}</strong>
      </div>
      <span class="ml-auto edited-badge" v-if="event.is_edited">
        <feather-icon :icon="'EditIcon'" />
        Відредаговано модератором
      </span>
    </div>
  </div>
</template>

<script>
import statusBadge from "@/components/ticket-status-badge";
export default {
  components: {
    statusBadge,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-left: 3px solid #126f81;
  padding: 12px;
  .moderation-badge {
    color: #f60;
    border: 1px solid;
    border-radius: 6px;
    padding: 0.45rem 0.6rem;
    line-height: 1;
  }
  .edited-badge {
    color: #126f81;
    // background: rgba(#126f81, 0.1);
    border-radius: 6px;
    font-size: 13px;
    padding: 0.45rem 0.6rem;
    line-height: 1;
  }
}
</style>
